import { $themeColors } from '@themeConfig'
// import store from '@/store'
// colors
const chartColors = {
  colors: ['#D55E00', '#0072B2', '#F0E442'],
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  label: ['Feminino', 'Masculino', 'Outros'],
}

export default {
  latestBarChart: {
    colors: chartColors.colors,
    label: chartColors.label,
    options: {
      elements: {
        rectangle: {
          borderWidth: 2,
          borderSkipped: 'bottom',
          maxBarThickness: 50,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      responsiveAnimationDuration: 500,
      legend: {
        display: true,
        position: 'top',
        align: 'center',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
        },
      },
      tooltips: {
        // Updated default tooltip UI
        shadowOffsetX: 1,
        shadowOffsetY: 1,
        shadowBlur: 8,
        shadowColor: chartColors.tooltipShadow,
        backgroundColor: $themeColors.light,
        titleFontColor: $themeColors.dark,
        bodyFontColor: $themeColors.dark,
      },
      scales: {
        xAxes: [
          {
            display: true,
            gridLines: {
              display: true,
              color: chartColors.grid_line_color,
              zeroLineColor: chartColors.grid_line_color,
            },
            scaleLabel: {
              display: false,
            },
            ticks: {
              fontColor: chartColors.labelColor,
            },
          },
        ],
        yAxes: [
          {
            display: true,
            gridLines: {
              color: chartColors.grid_line_color,
              zeroLineColor: chartColors.grid_line_color,
            },
            ticks: {
              stepSize: 10,
              min: 0,
              fontColor: chartColors.labelColor,
            },
          },
        ],
      },
    },
  },
}
