import { $themeColors } from '@themeConfig'
// colors
const chartColors = {
  colors: ['#26104D', '#0672B2', '#56B4E9', '#109E73', '#E69F27', '#D55E00', '#AA4499'],
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
}

export default {
  doughnutChart: {
    options: {
      responsive: true,
      maintainAspectRatio: false,
      responsiveAnimationDuration: 500,
      cutoutPercentage: 60,
      legend: { display: false },
      tooltips: {
        callbacks: {
          label(tooltipItem, data) {
            const label = data.datasets[0].labels[tooltipItem.index] || ''
            const value = data.datasets[0].data[tooltipItem.index]
            const output = ` ${label} : ${value}%`
            return output
          },
        },
        // Updated default tooltip UI
        shadowOffsetX: 1,
        shadowOffsetY: 1,
        shadowBlur: 8,
        shadowColor: chartColors.tooltipShadow,
        backgroundColor: $themeColors.light,
        titleFontColor: $themeColors.dark,
        bodyFontColor: $themeColors.dark,
      },
    },
    colors: chartColors.colors,
  },
}
