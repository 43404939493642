<template>
  <b-card no-body>
    <b-card-header>
      <h6>Entrada de paciente por gênero</h6>
    </b-card-header>

    <div v-if="totalMember !== 0">
      <!-- chart -->
      <b-card-body v-if="showChart">
        <chartjs-component-bar-chart
          :height="400"
          :data="chartDataGender.data"
          :options="chartjsDataGender.latestBarChart.options"
        />
      </b-card-body>
    </div>
    <div
      v-else
      class="mb-2 mt-3 text-center"
    >
      Você ainda não possui pacientes vinculados.
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody,
} from 'bootstrap-vue'

import moment from 'moment'
import 'twix'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import ChartjsComponentBarChart from '@/layouts/components/charts-components/ChartjsComponentBarChart.vue'
import chartjsDataGender from './chartjsDataGender'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,

    ChartjsComponentBarChart,
  },
  data() {
    return {
      showChart: false,
      chartDataGender: '',
      totalMember: '',
      chartjsDataGender,
    }
  },

  watch: {
    // eslint-disable-next-line func-names
    '$store.state.resume.dateStart': function () {
      this.changeData()
    },
    // eslint-disable-next-line func-names
    '$store.state.resume.dateEnd': function () {
      this.changeData()
    },
  },

  mounted() {
    this.changeData()
  },

  methods: {
    changeData() {
      this.showChart = false
      this.$store.dispatch('resume/listGender')
        .then(response => {
          this.totalMember = response.data.data.total
          this.loadChart(response)
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    loadChart(data) {
      const start = moment(this.$store.getters['resume/dateStart'], 'DD/MM/YYYY').format('MM/YYYY')
      const end = moment(this.$store.getters['resume/dateEnd'], 'DD/MM/YYYY').format('MM/YYYY')

      const range = []
      let format = ''

      if (start === end) {
        const itr = moment.twix(moment(this.$store.getters['resume/dateStart'], 'DD/MM/YYYY'),
          moment(this.$store.getters['resume/dateEnd'], 'DD/MM/YYYY')).iterate('day')

        while (itr.hasNext()) {
          range.push(itr.next().format('DD/MM/YYYY'))
        }

        format = 'day'
      } else {
        const itr = moment.twix(moment(this.$store.getters['resume/dateStart'], 'DD/MM/YYYY'),
          moment(this.$store.getters['resume/dateEnd'], 'DD/MM/YYYY')).iterate('month')

        while (itr.hasNext()) {
          range.push(itr.next().format('MM/YYYY'))
        }

        format = 'month'
      }

      const formData = {
        data: {
          labels: range,
          datasets: [
          ],
        },
      }

      const memberInfo = data.data.data.members_info

      const sumGender = []
      let memberPeridFormat = ''
      memberInfo.forEach(itemMember => {
        if (format === 'month') {
          memberPeridFormat = moment(itemMember.period).format('MM/YYYY')
        } else {
          memberPeridFormat = moment(itemMember.period).format('DD/MM/YYYY')
        }
        range.forEach((itemRange, index) => {
          if (itemRange === memberPeridFormat) {
            if (sumGender[index] !== undefined) {
              sumGender[index][0] += itemMember.female
              sumGender[index][1] += itemMember.male
              sumGender[index][2] += itemMember.others
            } else {
              sumGender.push([itemMember.female, itemMember.male, itemMember.others])
            }
          } else {
            sumGender.push([0, 0, 0])
          }
        })
      })

      const dataFemale = []
      const dataMale = []
      const dataOthers = []
      const dataGender = []

      sumGender.forEach(value => {
        dataFemale.push(value[0])
        dataMale.push(value[1])
        dataOthers.push(value[2])
      })

      dataGender.push(dataFemale)
      dataGender.push(dataMale)
      dataGender.push(dataOthers)

      const newDataset = []
      dataGender.forEach((value, index) => {
        const dataset = {
          data: value,
          backgroundColor: this.chartjsDataGender.latestBarChart.colors[index],
          borderColor: 'transparent',
          label: this.chartjsDataGender.latestBarChart.label[index],
        }
        newDataset.push(dataset)
      })

      formData.data.datasets = newDataset

      this.chartDataGender = formData
      this.showChart = true
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>
