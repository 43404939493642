<template>
  <div v-if="totalMembers !== 0">
    <b-card v-if="showChart">
      <!-- chart -->
      <chartjs-component-doughnut-chart
        :height="275"
        :data="chartDataAge.data"
        :options="chartjsDataAge.doughnutChart.options"
        class="mb-3"
      />
      <!--/ chart -->

      <div
        v-for="(stock,index) in label"
        :key="index"
        :class="index < label.length-1 ? 'mb-1':''"
        class="d-flex justify-content-between"
      >
        <div class="d-flex align-items-center">
          <feather-icon
            icon="CircleIcon"
            size="16"
            :fill="chartjsDataAge.doughnutChart.colors[index]"
            :stroke="chartjsDataAge.doughnutChart.colors[index]"
          />
          <span class="font-weight-bold ml-75 mr-25">{{ stock.title }}</span>
          <span>- {{ formatNumber(stock.value) }}%</span>
        </div>
      </div>

    </b-card>
  </div>
  <div
    v-else
    class="mb-2 mt-3"
  >
    Você ainda não possui pacientes vinculados.
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import ChartjsComponentDoughnutChart from '@/layouts/components/charts-components/ChartjsComponentDoughnutChart.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import chartjsDataAge from './chartjsDataAge'

export default {
  components: {
    ChartjsComponentDoughnutChart,
    BCard,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      showChart: false,
      totalMembers: '',
      labelData: ['Abaixo de 18 anos', '18 - 24 anos', '25 - 34 anos', '35 - 44 anos', '45 - 54 anos', '55 - 65 anos', 'Acima de 65 anos'],
      chartjsDataAge,
      chartDataAge: '',
      label: [],
      ageData: [],
    }
  },

  watch: {
    // eslint-disable-next-line func-names
    '$store.state.resume.dateStart': function () {
      this.loadCharts()
    },
    // eslint-disable-next-line func-names
    '$store.state.resume.dateEnd': function () {
      this.loadCharts()
    },
  },

  mounted() {
    this.loadCharts()
  },

  methods: {
    loadCharts() {
      this.showChart = false
      this.$store.dispatch('resume/listAge')
        .then(response => {
          this.totalMembers = response.data.data.total
          this.groupAge(response.data.data.members_info)
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    groupAge(data) {
      const arrayAge = [0, 0, 0, 0, 0, 0, 0]
      data.forEach(item => {
        if (item.age < 18) {
          arrayAge[0] += item.count
        } else if (item.age >= 18 && item.age <= 24) {
          arrayAge[1] += item.count
        } else if (item.age >= 25 && item.age <= 34) {
          arrayAge[2] += item.count
        } else if (item.age >= 35 && item.age <= 44) {
          arrayAge[3] += item.count
        } else if (item.age >= 45 && item.age <= 54) {
          arrayAge[4] += item.count
        } else if (item.age >= 55 && item.age <= 65) {
          arrayAge[5] += item.count
        } else if (item.age < 65) {
          arrayAge[6] += item.count
        }
      })
      this.percent(arrayAge)
      this.dataLabel(arrayAge)
      this.dataCharts()
      this.showChart = true
    },
    percent(data) {
      this.ageData = []
      data.forEach(value => {
        const percent = (value / this.totalMembers) * 100
        this.ageData.push(percent)
      })
    },
    dataLabel(data) {
      this.label = []
      for (let i = 0; i < 7; i += 1) {
        const temp = {
          title: this.labelData[i],
          value: (data[i] / this.totalMembers) * 100,
        }
        this.label.push(temp)
      }
    },
    formatNumber(data) {
      if (Number.isInteger(data)) {
        return data
      }
      return 0
    },
    dataCharts() {
      const formData = {
        data: {
          datasets: [
          ],
        },
      }

      const newDataset = [{
        labels: this.labelData,
        data: this.ageData,
        backgroundColor: chartjsDataAge.doughnutChart.colors,
        borderWidth: 0,
        pointStyle: 'rectRounded',
      }]
      formData.data.datasets = newDataset
      this.chartDataAge = formData
      this.showChart = true
    },
  },
}
</script>
