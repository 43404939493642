<template>
  <div>
    <div v-if="image === 1 && show">
      <b-modal
        ref="onboarding"
        no-close-on-backdrop
        centered
        hide-footer
        hide-header
        content-class="model-content"
        body-class="model-body"
      >
        <b-img src="@/assets/images/pages/resume/page_1.png" />
        <div style="text-align:center; margin-top:-60px">
          <feather-icon
            icon="MinusIcon"
            size="40"
            color="blue"
          />
          <feather-icon
            icon="MinusIcon"
            size="40"
            color="#E7EEFA"
            @click="next()"
          />
          <br>
          <br>
          <b-button
            variant="primary"
            @click="next()"
          >
            Próximo
          </b-button>
        </div>
      </b-modal>
    </div>

    <div v-else>
      <b-modal
        ref="onboarding"
        no-close-on-backdrop
        centered
        hide-footer
        hide-header
        content-class="model-content"
        body-class="model-body"
      >
        <b-img src="@/assets/images/pages/resume/page_2.png" />
        <div style="text-align:center ; margin-top:-60px">
          <feather-icon
            icon="MinusIcon"
            size="40"
            color="#E7EEFA"
            @click="previous()"
          />
          <feather-icon
            icon="MinusIcon"
            size="40"
            color="blue"
          />
          <br>
          <br>
          <b-button
            variant="primary"
            @click="close()"
          >
            Concluir
          </b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BModal, VBModal, BImg, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BModal,
    BImg,
    BButton,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },

  data() {
    return {
      image: 1,
      show: true,
    }
  },

  mounted() {
    if (this.$store.getters['login/onboarding'] === true) {
      this.$refs.onboarding.show()
    }
  },

  methods: {
    next() {
      this.show = false
      if (this.image === 1) {
        this.image = 2
      } else {
        this.image = 1
      }
      this.show = true
    },
    previous() {
      this.show = false
      if (this.image === 2) {
        this.image = 1
      } else {
        this.image = 2
      }
      this.show = true
    },
    close() {
      this.$store.commit('login/UPDATE_ONBOARDING', false)
      this.$refs.onboarding.hide()
    },
  },

}
</script>

<style>
.model-content {
  width: 900px;
  height: 650px;
}
.model-body {
  padding: 0px;
}
</style>
