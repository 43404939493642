<template>
  <div>
    <b-card>
      Nesta página você encontra os dados gerais dos seus pacientes ativos. Utilize os filtros para pesquisar por períodos diferentes. Você também pode fazer download dos dados em formato CSV.
    </b-card>

    <b-card>
      <b-row
        align-h="end"
      >
        <b-col md="4">
          <div>
            Data inicial/final:
            <flat-pickr
              v-model="date"
              class="form-control"
              :config="configDate"
              @input="dateChange()"
            />
          </div>
        </b-col>
        <b-col
          md="3"
          class="mt-2"
        >
          <b-button
            variant="outline-primary"
            @click="download()"
          >
            Baixar relatório em CSV
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-row>
      <b-col>
        <b-card>
          <h6>Pacientes vinculados</h6>
          <h2
            v-if="members === ''"
            class="font-weight-bolder mb-3 mt-3"
          >
            0
          </h2>
          <h2
            v-else
            class="font-weight-bolder mb-3 mt-3"
          >
            <strong>{{ members }}</strong>
          </h2>
        </b-card>
      </b-col>
      <b-col>
        <b-card>
          <h6>Adesão média dos últimos 30 dias</h6>
          <h2 class="font-weight-bolder mb-3 mt-3">
            <strong>{{ averageAdherenceValue }}%</strong>
          </h2>
        </b-card>
      </b-col>
    </b-row>

    <div>
      <b-card-group deck>
        <b-card>
          <h6>Medicamentos mais usados</h6>
          <div
            v-if="medication.length > 0"
            class="mb-2 mt-3"
          >
            <b-row
              v-for="(item, index) in medication"
              :key="index"
            >
              <b-col
                cols="10"
                class="mt-2"
              >
                {{ item.medication_name }}
              </b-col>
              <b-col class="mt-2">
                <strong>{{ item.count }}</strong>
              </b-col>
            </b-row>
          </div>
          <div
            v-else
            class="mb-2 mt-3 text-center"
          >
            Você ainda não possui pacientes com medicamentos vinculados.
          </div>
        </b-card>

        <b-card>
          <h6>Faixa etária</h6>
          <resume-age-chart />
        </b-card>

        <b-card>
          <h6>Condições de saúde mais relatadas</h6>
          <div
            v-if="care.length > 0"
            class="mb-2 mt-3"
          >
            <b-row
              v-for="(item, index) in care"
              :key="index"
            >
              <b-col
                cols="10"
                class="mt-2"
              >
                {{ item.care_name }}
              </b-col>
              <b-col class="mt-2">
                <strong>{{ item.count }}</strong>
              </b-col>
            </b-row>
          </div>
          <div
            v-else
            class="mb-2 mt-3 text-center"
          >
            Você ainda não possui pacientes com condições de saúde vinculadas.
          </div>
        </b-card>
      </b-card-group>
      <div
        v-if="showChartGender"
        class="mt-2"
      >
        <b-card>
          <resume-gender-chart />
        </b-card>
      </div>
    </div>
    <resume-modal-onboarding />

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BCardGroup, BButton,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Portuguese } from 'flatpickr/dist/l10n/pt'
import moment from 'moment'
import 'twix'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import resumeAgeChart from './Resume-age-chart.vue'
import resumeGenderChart from './Resume-gender-chart.vue'
import resumeModalOnboarding from './Resume-modal-onboarding.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardGroup,
    BButton,

    flatPickr,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,

    resumeAgeChart,
    resumeGenderChart,
    resumeModalOnboarding,
  },

  data() {
    return {
      date: `${moment().add(1, 'days').subtract(1, 'month').format('DD/MM/YYYY')} até ${moment().format('DD/MM/YYYY')}`,

      members: '',
      care: [],
      medication: [],
      showChartGender: false,
      averageAdherenceValue: '',

      configDate: {
        mode: 'range',
        dateFormat: 'd/m/Y',
        maxDate: 'today',
        locale: Portuguese,
      },
    }
  },

  mounted() {
    this.dateChange()
    this.countMember()
    this.averageAdherence()
  },

  methods: {
    countMember() {
      this.$store.dispatch('resume/countMembers')
        .then(response => {
          this.members = response.data.data.members_count
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    listCare() {
      this.$store.dispatch('resume/listCare')
        .then(response => {
          this.care = response.data.data.member_cares_info
          if (this.care.length > 10) {
            this.care.length = 10
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    listMedication() {
      this.$store.dispatch('resume/listMedication')
        .then(response => {
          this.medication = response.data.data.member_medications_info
          if (this.medication.length > 10) {
            this.medication.length = 10
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    averageAdherence() {
      this.$store.dispatch('resume/averageAdherence')
        .then(response => {
          const adherenceAverage = response.data.data.members_adherence_average
          this.averageAdherenceValue = Math.round(adherenceAverage)
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    dateChange() {
      this.showChartGender = false

      this.arrayDate = this.date.split(' ')
      if (this.arrayDate.length === 3) {
        this.$store.commit('resume/UPDATE_DATE_START', this.arrayDate[0])
        this.$store.commit('resume/UPDATE_DATE_END', this.arrayDate[2])
      } else {
        this.$store.commit('resume/UPDATE_DATE_START', this.arrayDate[0])
        this.$store.commit('resume/UPDATE_DATE_END', this.arrayDate[0])
      }
      this.listCare()
      this.listMedication()
      this.showChartGender = true
    },
    download() {
      this.$store.dispatch('resume/downloadCsv')
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/csv' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = 'relatório.csv'
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>
